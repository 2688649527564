import axios, { AxiosResponse } from "axios";
import { AdvertisementList, Article, License, LicenseList, NewsPagedListBody, ResponseBody } from "./index.type";

const remote = axios.create({
  baseURL: window.BASE_URL,
});

export function getAdvertisement(): Promise<AxiosResponse<ResponseBody<AdvertisementList>>> {
  return remote.get("/api/AdChannel").then((res) => {
    return res;
  });
}

// export function getLicenseList(): Promise<AxiosResponse<ResponseBody<LicenseList>>> {
//   return remote.get("/cms/licenseList");
// }

export function queryLicense(code: string): Promise<AxiosResponse<ResponseBody<License>>> {
  return remote.post(`/api/QyLiceseCode?scode=${code}`);
}

export function getAnnouncementNewsPagedList(page: number | string): Promise<AxiosResponse<ResponseBody<NewsPagedListBody>>> {
  return remote.get(`/cms/newsListData?idx=${page}`);
}

export function getCombatNewsPagedList(page: number | string): Promise<AxiosResponse<ResponseBody<NewsPagedListBody>>> {
  return remote.get(`/cms/wfqqdjListData?idx=${page}`);
}

export function getAnnouncementOrNewsArticle(token: string): Promise<AxiosResponse<ResponseBody<Article>>> {
  return remote.get(`/cms/Getwfqq?tk=${token}`);
}

export function getCooperationChannel(): Promise<AxiosResponse<ResponseBody<string>>> {
  return remote.get("/api/CooperationChannel");
}

export function getContacts(): Promise<AxiosResponse<ResponseBody<string>>> {
  return remote.get("/api/wqGetQQServer");
}
