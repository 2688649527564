import { isString } from "@vue/shared";

import { onMounted, ref, watch } from "vue";
import { LocationQueryValue, useRoute } from "vue-router";

export function usePageNumber(refresh: (page: number) => void) {
  const route = useRoute();
  const pageNumber = ref(parsePageIndex(route.query.pageNumber));

  watch(
    () => route.query.pageNumber,
    function () {
      pageNumber.value = parsePageIndex(route.query.pageNumber);
      refresh(pageNumber.value);
    }
  );

  function parsePageIndex(query: LocationQueryValue | LocationQueryValue[]) {
    if (!isString(query)) return 1;
    const value = parseInt(query);
    return isNaN(value) ? 1 : value;
  }

  onMounted(() => {
    refresh(pageNumber.value);
  });

  return {
    pageNumber,
  };
}
