import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { getContacts } from "../data";

interface State {
  contacts: string[] | null;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state() {
    return {
      contacts: null,
    };
  },
  mutations: {
    setContacts(state, values: string[]) {
      state.contacts = values;
    },
  },
  actions: {
    initContacts(context) {
      if (context.state.contacts !== null) return;
      getContacts().then((res) => {
        context.commit("setContacts", res.data.data.split(","));
      });
    },
  },
});

export function useStore() {
  return baseUseStore(key);
}
