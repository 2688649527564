import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Cooperation from "../views/Cooperation.vue";
import Lisence from "../views/License.vue";
import Announcement from "../views/Announcement.vue";
import Combat from "../views/Combat.vue";
import AnnouncementDetails from "../views/AnnouncementDetails.vue";
import CombatDetails from "../views/CombatDetails.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/cooperation",
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: Cooperation,
    meta: {
      index: 2,
      title: "官方合作-金丰科技信息(深圳)集团有限公司",
    },
  },
  {
    path: "/license",
    name: "license",
    component: Lisence,
    meta: {
      index: 3,
      title: "开区合作-金丰科技信息(深圳)集团有限公司",
    },
  },
  {
    path: "/announcement",
    name: "announcement",
    component: Announcement,
    meta: {
      index: 4,
      title: "政策公告-金丰科技信息(深圳)集团有限公司",
    },
  },
  {
    path: "/announcement/:token",
    name: "announcement-details",
    component: AnnouncementDetails,
    meta: {
      index: 4,
      title: "政策公告-金丰科技信息(深圳)集团有限公司",
    },
  },
  {
    path: "/combat",
    name: "combat",
    component: Combat,
    meta: {
      index: 5,
      title: "维权打击-金丰科技信息(深圳)集团有限公司",
    },
  },
  {
    path: "/combat/:token",
    name: "combat-details",
    component: CombatDetails,
    meta: {
      index: 5,
      title: "维权打击-金丰科技信息(深圳)集团有限公司",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to) => {
  const title = to.meta.title as string | undefined;
  if (title !== undefined) {
    window.document.title = title;
  }
  if (to.name !== "cooperation") {
    document.body.className = "bg-1";
  } else {
    document.body.className = "bg-2";
  }
});

export default router;
