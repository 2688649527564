<template>
  <div :class="$style.root">
    <div :class="$style.head"></div>
    <div :class="$style.content">
      <div :class="$style.title"></div>
      <div :class="[$style.star, $style['star-five']]"></div>
      <div :class="$style.level">五星金牌合作</div>
      <div :class="$style.code">JF00001</div>
      <div :class="$style.query"></div>
    </div>
  </div>
</template>

<style module>
.root {
  width: 220px;
  height: 285px;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  opacity: 0.7;
}

.root:hover {
  opacity: 1;
}

.head {
  width: 180px;
  height: 48px;
  margin: 0 auto;
  margin-bottom: 5px;
  background: url(../assets/license-demo.png);
}

.content {
  width: 100%;
  height: 232px;
  position: relative;
  background: url(../assets/license-bg.png) no-repeat;
}

.title {
  width: 184px;
  height: 57px;
  background: url(../assets/license-title.gif) no-repeat;
  position: absolute;
  top: 42px;
  left: 42px;
}

.star {
  width: 184px;
  height: 64px;
  position: absolute;
  top: 95px;
  left: 42px;
}

.star-five {
  background: url(../assets/five-star.gif) no-repeat;
}

.level {
  width: 100%;
  font-size: 12px;
  color: #d4000b;
  position: absolute;
  top: 143px;
  text-align: center;
}

.code {
  position: absolute;
  bottom: 50px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  color: #61431e;
  width: 100%;
}

.query {
  position: absolute;
  bottom: 11px;
  left: 88px;
  height: 20px;
  width: 44px;
  cursor: pointer;
}
</style>
