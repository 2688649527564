const NUMBER: Record<string, string | undefined> = {
  "1": "一",
  "2": "二",
  "3": "三",
  "4": "四",
  "5": "五",
};

const HEADER = [
  { text: "首页", href: "https://www.szjfjt.com/" },
  { text: "新闻动态", href: "https://www.szjfjt.com/news.html" },
  { text: "官方合作", href: "/#/cooperation" },
  { text: "开区合作", href: "/#/license" },
  { text: "政策公告", href: "/#/announcement" },
  { text: "维权打击", href: "/#/combat" },
  { text: "联系我们", href: "https://www.szjfjt.com/contact.html" },
];

const FOOTER = {
  sections: [
    {
      title: "新闻动态",
      list: [
        { text: "新闻动态", href: "https://www.szjfjt.com/news.html" },
        { text: "合作公示", href: "/#/cooperation" },
      ],
    },
    {
      title: "公司介绍",
      list: [
        { text: "公司简介", href: "https://www.szjfjt.com" },
        { text: "相关资质", href: "https://www.szjfjt.com" },
      ],
    },
    {
      title: "联系我们",
      list: [
        { text: "联系方式", href: "https://www.szjfjt.com/contact.html" },
        { text: "联系方式", href: "https://www.szjfjt.com/contact.html" },
      ],
    },
  ],
  copyright: "金丰科技信息(深圳)集团有限公司 版权所有",
  record: {
    text: "粤ICP备2022066737号",
    href: "http://beian.miit.gov.cn/",
  },
};

const licenseType = [
  undefined,
  { text: "月度授权", color: "#ad6cdc" },
  { text: "季度授权", color: "#6f87ff" },
  { text: "半年授权", color: "#dcb24c" },
  { text: "年度授权", color: "#ea5e5e" }
];
export { NUMBER, HEADER, FOOTER, licenseType };
