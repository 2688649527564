export * from "./statistic";
export class SliderTrigger {
  $element: HTMLElement;
  $data: Record<string, number> = {};
  $keys: string[];
  $current?: string;
  $index = -1;
  onChange: ((key: string) => void) | undefined = undefined;
  constructor(element: HTMLElement) {
    this.$element = element;
    for (const e of element.children) {
      const key = e.getAttribute("data-key");
      if (key !== null) this.$data[key] = (e as HTMLElement).offsetTop;
    }
    this.$keys = Object.keys(this.$data);
    if (this.$keys.length > 0) {
      this.$index = 0;
      this.$current = this.$keys[this.$index];
    }
  }

  refresh(value: number) {
    if (this.isEmpty()) return;
    const prev = this.getPrev();
    const next = this.getNext();

    if (prev !== null && value < this.$data[this.$current!]) {
      this.prev();
      this.refresh(value);
      return;
    }

    if (next !== null && value > this.$data[next]) {
      this.next();
      this.refresh(value);
      return;
    }
  }

  next() {
    this.$index += 1;
    this.$current = this.$keys[this.$index];
    if (this.onChange) this.onChange(this.$current);
  }

  prev() {
    this.$index -= 1;
    this.$current = this.$keys[this.$index];
    if (this.onChange) this.onChange(this.$current);
  }

  getPrev() {
    return this.isFirst(this.$current!) ? null : this.$keys[this.$index - 1];
  }

  getNext() {
    return this.isLast(this.$current!) ? null : this.$keys[this.$index + 1];
  }

  isFirst(key: string) {
    return this.$keys[0] === key;
  }

  isLast(key: string) {
    return this.$keys[this.$keys.length - 1] === key;
  }

  isEmpty() {
    return this.$current === null || this.$index < 0;
  }
  isKey(key: string) {
    return this.$data[key] !== undefined;
  }
}

export function getHost(url: string) {
  try {
    return new URL(url).host;
  } catch {
    return url;
  }
}
