export function useStatistic() {
  if (document.getElementById("hm.baidu.com")) {
    return;
  }
  window._hmt = window._hmt || [];
  (function () {
    const hm = document.createElement("script");
    hm.id = "hm.baidu.com";
    hm.src = "https://hm.baidu.com/hm.js?0355161746b09240c8212f8bf0cefaa2";
    const s = document.getElementsByTagName("script")[0];
    s.parentNode!.insertBefore(hm, s);
  })();
}
