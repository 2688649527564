import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./css/normolize.css";
import "./css/style.css";

createApp(App).use(router).use(store, key).mount("#app");
